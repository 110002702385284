import { useOrganization } from '@capturi/stores'
import React from 'react'

import { DashboardFolder, DashboardListItem } from '../types'
import { useDashboardFolders } from './useDashboardFolders'
import { useDashboards } from './useDashboards'

export type FolderListItem = {
  folder: DashboardFolder
  searchMatchType?: 'folder' | 'dashboard'
  dashboards: DashboardListItem[]
}

export function useTableOfContents(searchTerm?: string): {
  allFolders: DashboardFolder[] | undefined
  allDashboards: DashboardListItem[] | undefined
  filteredFolders: FolderListItem[]
  filteredRootDashboards: DashboardListItem[]
  dashboardsByFolderUid: Map<string | null, DashboardListItem[]>
  isEmpty: boolean
} {
  const { conversationLanguage } = useOrganization()
  const compare = Intl.Collator(conversationLanguage).compare
  const { data: folders } = useDashboardFolders()
  const { data: dashboards } = useDashboards()

  const dashboardsByFolderUid = React.useMemo(
    () =>
      (dashboards ?? [])?.reduce<Map<string | null, DashboardListItem[]>>(
        (memo, dashboard) => {
          const key = dashboard?.folderUid || null
          const a = memo.get(key) || []
          a.push(dashboard)
          memo.set(key, a)
          return memo
        },
        new Map(),
      ),
    [dashboards],
  )

  const lowercasedSearchQuery = searchTerm?.toLowerCase()
  const filteredFolders =
    React.useMemo(
      () =>
        folders
          ?.reduce<FolderListItem[]>((memo, f) => {
            const dashboards = (dashboardsByFolderUid.get(f.uid) || [])?.sort(
              (a, b) => compare(a.title ?? a.uid, b.title ?? b.uid),
            )
            if (lowercasedSearchQuery && lowercasedSearchQuery.length > 0) {
              const filteredDashboards = dashboards.filter((dashboard) =>
                dashboard.title?.toLowerCase()?.includes(lowercasedSearchQuery),
              )
              if (filteredDashboards.length > 0) {
                memo.push({
                  folder: f,
                  dashboards: filteredDashboards,
                  searchMatchType: 'dashboard',
                })
              } else if (
                f.title?.toLowerCase()?.includes(lowercasedSearchQuery)
              ) {
                memo.push({
                  folder: f,
                  dashboards: dashboards,
                  searchMatchType: 'folder',
                })
              }
            } else {
              memo.push({
                folder: f,
                dashboards: dashboards,
              })
            }
            return memo
          }, [])
          .sort((a, b) =>
            compare(
              a.folder.title ?? a.folder.uid,
              b.folder.title ?? b.folder.uid,
            ),
          ),
      [compare, dashboardsByFolderUid, folders, lowercasedSearchQuery],
    ) ?? []

  const filteredRootDashboards = React.useMemo(() => {
    let dashboards = dashboardsByFolderUid.get(null) || []
    if (lowercasedSearchQuery && lowercasedSearchQuery.length > 0) {
      dashboards = dashboards.filter((dashboard) =>
        dashboard.title?.toLowerCase()?.includes(lowercasedSearchQuery),
      )
    }
    return dashboards.sort((a, b) =>
      compare(a?.title ?? a.uid, b?.title ?? b.uid),
    )
  }, [compare, dashboardsByFolderUid, lowercasedSearchQuery])

  return {
    allFolders: folders,
    allDashboards: dashboards,
    filteredFolders,
    filteredRootDashboards,
    dashboardsByFolderUid,
    isEmpty: (folders?.length ?? 0) === 0 && (dashboards?.length ?? 0) === 0,
  }
}

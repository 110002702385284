import analytics from '@capturi/analytics'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { useCurrentUser } from '@capturi/core'
import {
  CreateDashboardRequestModel,
  CreateUpdateDashboardDialog,
  CreateUpdateDashboardFolderDialog,
  DashboardAdminEvent,
  DashboardFolder,
  DashboardListItem as DashboardListItemType,
  FolderListItem,
  logEvent,
  useCreateDashboard,
  useTableOfContents,
} from '@capturi/dashboard'
import { useFilterPeriodContext } from '@capturi/filters'
import { usePageTitle } from '@capturi/react-utils'
import {
  Button,
  ContentPlaceholder,
  PageHeading,
  useToast,
} from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Divider,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useRef, useState } from 'react'
import {
  MdClose,
  MdCreateNewFolder,
  MdDashboard,
  MdSearch,
} from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import routes from '../routes'
import { DashboardFolderListItem } from './DashboardFolderListItem'
import { DashboardItemsList, DashboardListItem } from './DashboardListItem'
import { useMostRecentDashboardAndFolder } from './useMostRecentDashboardAndFolderUid'

const ListPage: React.FC = () => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const toast = useToast()
  const {
    isOpen: dashboardDialogIsOpen,
    onOpen: openDashboardDialog,
    onClose: closeDashboardDialog,
  } = useDisclosure()
  const [openCreateEditDashboardFolderDialog] = useModal(
    CreateUpdateDashboardFolderDialog,
  )
  const { periodDef } = useFilterPeriodContext()
  const [searchTerm, setSearchTerm] = useState('')

  const { mutate: createDashboard } = useCreateDashboard()

  const {
    isEmpty,
    filteredFolders,
    filteredRootDashboards,
    dashboardsByFolderUid,
  } = useTableOfContents(searchTerm)

  const onCreateNewDashboard = useCallback(() => {
    logEvent(DashboardAdminEvent.OpenCreateDialog)
    openDashboardDialog()
  }, [openDashboardDialog])

  const onCreateNewFolder = useCallback((): void => {
    logEvent(DashboardAdminEvent.OpenCreateFolderDialog)
    openCreateEditDashboardFolderDialog()
  }, [openCreateEditDashboardFolderDialog])

  const handleEditFolder = useCallback(
    (folder: DashboardFolder): void => {
      logEvent(DashboardAdminEvent.OpenEditFolderDialog)
      openCreateEditDashboardFolderDialog({ folder })
    },
    [openCreateEditDashboardFolderDialog],
  )

  const handleSelectDashboard = useCallback(
    (x: DashboardListItemType): void => {
      analytics.event('dashboard-list_SelectDashboard', {
        title: x.title,
        uid: x.uid,
        folder: x.folderUid,
      })
      navigate(routes.editor(x.uid))
    },
    [navigate],
  )

  const handleDashboardSave = async (
    model: Partial<CreateDashboardRequestModel>,
  ): Promise<void> => {
    try {
      createDashboard(model as CreateDashboardRequestModel, {
        onSuccess: (dashboard) => {
          logEvent(DashboardAdminEvent.DashboardCreated)
          toast({
            title: t`Dashboard created`,
            status: 'success',
          })
          navigate(routes.editor(dashboard.uid))
        },
      })
    } catch {
      toast({
        title: t`An error occurred`,
        description: t`The dashboard "${model.title}" was not created`,
        status: 'error',
      })
    }
  }

  if (isEmpty) {
    return (
      <>
        <Box>
          <PageHeading mb={2} w="fit-content" data-stonly="dashboards-header">
            {t`Dashboards`}
          </PageHeading>
        </Box>
        <ContentPlaceholder.Container mt="10vh">
          <ContentPlaceholder.Image as={Icon_EmptyState} />
          <ContentPlaceholder.Heading>
            <Trans>It&apos;s a bit empty here!</Trans>
          </ContentPlaceholder.Heading>
          <ContentPlaceholder.Body>
            <Text>
              <Trans>
                Dashboards help you get a better overview of your efforts and
                goals.
              </Trans>
            </Text>
            <Text mt={4}>
              <Trans>
                You can share a dashboard with others or put it up on a big
                screen in the office.
              </Trans>
            </Text>
          </ContentPlaceholder.Body>
          <ContentPlaceholder.Footer>
            <Menu>
              <MenuButton
                as={Button}
                aria-label={t`Add dashboard or folder`}
                backgroundColor="primary.500"
                color="white"
                leftIcon={<MdDashboard />}
                primary
                flexShrink={0}
                _hover={{ backgroundColor: 'primary.600' }}
                _focus={{ backgroundColor: 'primary.600' }}
              >
                <Trans>Add new</Trans>
              </MenuButton>
              <Portal>
                <MenuList>
                  <MenuItem
                    icon={<MdDashboard />}
                    onClick={onCreateNewDashboard}
                  >
                    <Trans>Dashboard</Trans>
                  </MenuItem>
                  <MenuItem
                    icon={<MdCreateNewFolder />}
                    onClick={onCreateNewFolder}
                  >
                    <Trans>Dashboard folder</Trans>
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>

            {dashboardDialogIsOpen && (
              <CreateUpdateDashboardDialog
                periodDef={periodDef}
                isOpen={dashboardDialogIsOpen}
                onClose={closeDashboardDialog}
                onSubmit={handleDashboardSave}
              />
            )}
          </ContentPlaceholder.Footer>
        </ContentPlaceholder.Container>
      </>
    )
  }

  return (
    <>
      <Flex gap={4} alignItems="center" mb={2}>
        <Box flexGrow="1">
          <PageHeading mb={2} w="fit-content" data-stonly="dashboards-header">
            {t`Dashboards`}
          </PageHeading>
        </Box>

        <InputGroup width="30%" maxW="200px" size="sm">
          <InputLeftElement pointerEvents="none">
            <Icon fontSize="14px" as={MdSearch} />
          </InputLeftElement>
          <Input
            placeholder={t`Search...`}
            onChange={(e) => {
              setSearchTerm(e.currentTarget.value)
            }}
            value={searchTerm}
            borderColor="gray.200"
            borderRadius={4}
          />
          {searchTerm && searchTerm.length > 0 ? (
            <InputRightElement cursor="pointer">
              <Icon
                fontSize="14px"
                as={MdClose}
                onClick={() => setSearchTerm('')}
              />
            </InputRightElement>
          ) : null}
        </InputGroup>
        {currentUser.permissions.editDashboard && (
          <Flex justify="center">
            <Menu>
              <MenuButton
                as={Button}
                aria-label={t`Add dashboard or folder`}
                backgroundColor="primary.500"
                color="white"
                leftIcon={<MdDashboard />}
                primary
                flexShrink={0}
                _hover={{ backgroundColor: 'primary.600' }}
                _focus={{ backgroundColor: 'primary.600' }}
              >
                <Trans>Add new</Trans>
              </MenuButton>
              <Portal>
                <MenuList>
                  <MenuItem
                    icon={<MdDashboard />}
                    onClick={onCreateNewDashboard}
                  >
                    <Trans>Dashboard</Trans>
                  </MenuItem>
                  <MenuItem
                    icon={<MdCreateNewFolder />}
                    onClick={onCreateNewFolder}
                  >
                    <Trans>Dashboard folder</Trans>
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>

            {dashboardDialogIsOpen && (
              <CreateUpdateDashboardDialog
                periodDef={periodDef}
                isOpen={dashboardDialogIsOpen}
                onClose={closeDashboardDialog}
                onSubmit={handleDashboardSave}
              />
            )}
          </Flex>
        )}
      </Flex>

      <FoldersAndDashboardsList
        folders={filteredFolders}
        rootDashboards={filteredRootDashboards}
        dashboardsByFolderUid={dashboardsByFolderUid}
        onSelectDashboard={handleSelectDashboard}
        onEditFolder={handleEditFolder}
      />
    </>
  )
}

const FoldersAndDashboardsList: React.FC<{
  dashboardsByFolderUid: Map<string | null, DashboardListItemType[]>
  folders: FolderListItem[] | undefined
  rootDashboards: DashboardListItemType[] | undefined
  onSelectDashboard: (dashboard: DashboardListItemType) => void
  onEditFolder: (folder: DashboardFolder) => void
}> = ({
  dashboardsByFolderUid,
  folders: items,
  rootDashboards,
  onSelectDashboard,
  onEditFolder,
}) => {
  const itemRef = useRef<HTMLLIElement>(null)
  const hasScrolledToFocusedRef = useRef(false)
  const { mostRecentDashboardUid, mostRecentFolderUid } =
    useMostRecentDashboardAndFolder()

  if (isEmpty(items) && isEmpty(rootDashboards)) {
    return (
      <Text color="textMuted" mt={4} textAlign="center">
        <Trans>No dashboards or folders found</Trans>
      </Text>
    )
  }

  return (
    <Box>
      <List spacing={6} mt="24px">
        {items?.map((f) => (
          <DashboardFolderListItem
            key={`folder_${f.folder.uid}`}
            item={f}
            dashboardCount={
              dashboardsByFolderUid.get(f.folder.uid)?.length ?? 0
            }
            hasFocus={f.folder.uid === mostRecentFolderUid}
            itemRef={itemRef}
            hasScrolledToFocusedRef={hasScrolledToFocusedRef}
            onSelectDashboard={onSelectDashboard}
            onEditFolder={onEditFolder}
            searchMatchType={f.searchMatchType}
          />
        ))}
      </List>

      {items?.length !== 0 && rootDashboards?.length !== 0 && (
        <Divider
          orientation="horizontal"
          w="100%"
          borderColor="gray.400"
          display="inline-block"
          my={6}
        />
      )}

      <DashboardItemsList spacing={4} mt={items?.length === 0 ? '24px' : '0px'}>
        {rootDashboards?.map((d) => (
          <DashboardListItem
            key={`dashboard_${d.uid}`}
            dashboard={d}
            hasFocus={d.uid === mostRecentDashboardUid}
            itemRef={itemRef}
            hasScrolledToFocusedRef={hasScrolledToFocusedRef}
            onSelectDashboard={onSelectDashboard}
          />
        ))}
      </DashboardItemsList>
    </Box>
  )
}

export default function ListPageWrapper(): React.ReactElement {
  usePageTitle(t`Dashboards`)
  return <ListPage />
}

import {
  DashboardFolder,
  DashboardListItem as DashboardListItemType,
  FolderListItem,
  useDeleteFolderConfirmationDialog,
} from '@capturi/dashboard'
import { useHover } from '@capturi/react-utils'
import { createExpandedFoldersStore } from '@capturi/stores'
import {
  ListItem as CharkaListItem,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useMergeRefs,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useEffect, useRef } from 'react'
import { MdChevronRight, MdDelete, MdEdit, MdMoreVert } from 'react-icons/md'
import { useStore } from 'zustand'

import { DashboardItemsList, DashboardListItem } from './DashboardListItem'

const expandedFolders = createExpandedFoldersStore('expanded-dashboard-folders')

export const DashboardFolderListItem: React.FC<{
  item: FolderListItem
  dashboardCount: number
  hasFocus?: boolean
  itemRef?: React.MutableRefObject<HTMLLIElement | null>
  hasScrolledToFocusedRef?: React.MutableRefObject<boolean>
  onSelectDashboard: (dashboard: DashboardListItemType) => void
  onEditFolder: (folder: DashboardFolder) => void
  searchMatchType: FolderListItem['searchMatchType']
}> = ({
  item,
  dashboardCount,
  hasFocus,
  itemRef,
  hasScrolledToFocusedRef,
  onSelectDashboard,
  onEditFolder,
  searchMatchType,
}) => {
  const containerRef = useRef<HTMLLIElement>(null)
  const isHovering = useHover(containerRef)
  const didHaveFocusRef = useRef(hasFocus)
  const mergedRefs = useMergeRefs(
    containerRef,
    didHaveFocusRef.current ? itemRef : null,
  )

  useEffect(() => {
    if (
      hasScrolledToFocusedRef &&
      !hasScrolledToFocusedRef.current &&
      didHaveFocusRef.current &&
      itemRef
    ) {
      itemRef?.current?.scrollIntoView({ block: 'center' })
      hasScrolledToFocusedRef.current = true
    }
  }, [itemRef, hasScrolledToFocusedRef])

  const openDeleteFolderDialog = useDeleteFolderConfirmationDialog({
    folderUid: item.folder.uid,
  })
  const expandedFoldersStore = useStore(expandedFolders)
  const isDisabled = searchMatchType === 'dashboard'
  const isExpanded = expandedFoldersStore.has(item.folder.uid) || isDisabled

  const handleFolderClick = React.useCallback(() => {
    if (!isDisabled) expandedFoldersStore.toggle(item.folder.uid)
  }, [expandedFoldersStore, isDisabled, item.folder.uid])

  return (
    <CharkaListItem
      ref={mergedRefs}
      cursor={isDisabled ? undefined : 'pointer'}
      onClick={handleFolderClick}
    >
      <Flex h={10} align="center">
        <Icon
          as={MdChevronRight}
          transform={isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'}
          transition="all 200ms ease-out"
        />

        <Text
          color={isHovering || isExpanded ? 'gray.800' : 'gray.600'}
          fontFamily="heading"
          fontSize="2xl"
          fontWeight="medium"
          noOfLines={1}
          overflow="hidden"
          display="inline-block"
          ml={2}
        >
          {`${item.folder.title} (${dashboardCount})`}
        </Text>

        {item.folder.accessLevel === 'Edit' && (
          <FolderMenu
            isHovering={isHovering}
            onEditFolder={onEditFolder}
            item={item}
            openDeleteFolderDialog={openDeleteFolderDialog}
          />
        )}
      </Flex>

      <Collapse
        in={isExpanded}
        style={{
          overflow: isExpanded
            ? 'visible' // for DashboardItemList shadow
            : 'hidden', // for Collapse animation
        }}
      >
        <DashboardItemsList mt={1}>
          {item.dashboards.length > 0 ? (
            item.dashboards.map((d) => (
              <DashboardListItem
                key={`dashboard_${d.uid}`}
                dashboard={d}
                itemRef={itemRef}
                onSelectDashboard={onSelectDashboard}
              />
            ))
          ) : (
            <Flex justifyContent="center">
              <Text fontSize="sm" color="gray.600">
                <Trans>Folder is empty...</Trans>
              </Text>
            </Flex>
          )}
        </DashboardItemsList>
      </Collapse>
    </CharkaListItem>
  )
}

const FolderMenu: React.FC<{
  isHovering: boolean
  onEditFolder: (folder: DashboardFolder) => void
  item: FolderListItem
  openDeleteFolderDialog: () => void
}> = React.memo(
  ({ isHovering, onEditFolder, item, openDeleteFolderDialog }) => {
    return (
      <Menu isLazy>
        <MenuButton
          transition="visibility 200ms linear,opacity 200ms linear"
          visibility={isHovering ? 'visible' : 'hidden'}
          opacity={isHovering ? 100 : 0}
          variant="ghost"
          float="right"
          as={IconButton}
          icon={<MdMoreVert />}
          size="xs"
          onClick={(e) => e.stopPropagation()}
          ml={1}
        />
        <Portal>
          <MenuList>
            <MenuItem
              icon={<MdEdit />}
              onClick={(e) => {
                e.stopPropagation()
                onEditFolder(item.folder)
              }}
            >
              <Trans>Edit</Trans>
            </MenuItem>
            <MenuItem
              icon={<MdDelete />}
              onClick={(e) => {
                e.stopPropagation()
                openDeleteFolderDialog()
              }}
            >
              <Trans>Delete folder</Trans>
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    )
  },
)

import {
  DashboardAdminEvent,
  DashboardFolder,
  DashboardListItem as DashboardListItemType,
  logEvent,
  useDashboardActions,
  useDashboardFolders,
} from '@capturi/dashboard'
import { FolderMenu } from '@capturi/ui-components'
import {
  Box,
  Flex,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemProps,
  ListProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
  chakra,
  useMergeRefs,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans, t } from '@lingui/macro'
import React, { forwardRef, useRef } from 'react'
import { FiLink } from 'react-icons/fi'
import { IoMdEyeOff } from 'react-icons/io'
import { MdMoreVert, MdMoveToInbox } from 'react-icons/md'

export const DashboardItemsList = forwardRef<HTMLUListElement, ListProps>(
  function DashboardItemsList({ children, ...restProps }, ref) {
    return (
      <List
        filter="drop-shadow(0px -4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04))"
        borderRadius={4}
        ref={ref}
        {...restProps}
      >
        {children}
      </List>
    )
  },
)

export const DashboardListItem: React.FC<
  {
    dashboard: DashboardListItemType
    hasFocus?: boolean
    itemRef?: React.RefObject<HTMLLIElement>
    hasScrolledToFocusedRef?: React.MutableRefObject<boolean>
    onSelectDashboard: (dashboard: DashboardListItemType) => void
  } & Omit<ListItemProps, 'ref' | 'itemRef'>
> = ({
  dashboard,
  hasFocus,
  itemRef,
  hasScrolledToFocusedRef,
  onSelectDashboard,
  ...restProps
}) => {
  const { data: folders } = useDashboardFolders('Edit')

  const containerRef = useRef<HTMLLIElement>(null)
  const didHaveFocusRef = useRef(hasFocus)
  const mergedRefs = useMergeRefs(
    containerRef,
    didHaveFocusRef.current ? itemRef : null,
  )

  React.useEffect(() => {
    if (
      hasScrolledToFocusedRef &&
      !hasScrolledToFocusedRef.current &&
      didHaveFocusRef.current &&
      itemRef
    ) {
      itemRef?.current?.scrollIntoView({ block: 'center' })
      hasScrolledToFocusedRef.current = true
    }
  }, [itemRef, hasScrolledToFocusedRef])

  const { updateDashboard } = useDashboardActions({ uid: dashboard.uid })
  const handleMoveToFolder = React.useCallback(
    (folderUid: string | null) => {
      updateDashboard({ folderUid })

      logEvent(
        folderUid
          ? DashboardAdminEvent.DashboardFolderAssignedViaQuickMenu
          : DashboardAdminEvent.DashboardFolderClearedViaQuickMenu,
      )
    },
    [updateDashboard],
  )

  const handleSelectDashboard = React.useCallback(() => {
    onSelectDashboard(dashboard)
  }, [onSelectDashboard, dashboard])

  return (
    <ListItem
      overflow="visible"
      onClick={handleSelectDashboard}
      cursor="pointer"
      ref={mergedRefs}
      background="gray.50"
      borderBottom={dashboard.folderUid === null ? 'none' : '1px solid'}
      borderBottomColor="gray.300"
      borderRadius={dashboard.folderUid === null ? '4px' : '0'}
      css={css`
        :last-child {
          border-bottom: none;
          border-radius: 0px 0px 4px 4px;
        }
        :first-of-type {
          border-radius: 4px 4px 0px 0px;
        }
      `}
      _hover={{ background: 'gray.100' }}
      {...restProps}
    >
      <Box py={4} px={6}>
        <Flex alignItems="stretch" gap={4}>
          <VStack flex="1 1" alignItems="start">
            <Text fontFamily="heading" fontSize="xl">
              {dashboard.title}
            </Text>
            <Text color="gray.600" fontSize="md">
              {dashboard.description}
            </Text>
          </VStack>
          <SimpleGrid columns={3} spacing={0} alignItems="baseline">
            <Box display="flex" justifyContent="flex-end">
              {dashboard.permissionPreset === 'Private' && (
                <Tooltip
                  hasArrow
                  placement="top"
                  label={t`Dashboard is private and is only visible to you`}
                  isDisabled={dashboard.permissionPreset !== 'Private'}
                >
                  <Box as="span">
                    {dashboard.permissionPreset === 'Private' && (
                      <Icon as={IoMdEyeOff} float="right" />
                    )}
                  </Box>
                </Tooltip>
              )}
            </Box>

            <Box display="flex" justifyContent="start">
              <Tooltip
                hasArrow
                placement="top"
                label={t`Dashboard can be shared with public link`}
                isDisabled={!dashboard.hasAccessKey}
              >
                <Box as="span">
                  {dashboard.hasAccessKey && <Icon as={FiLink} float="left" />}
                </Box>
              </Tooltip>
            </Box>
            <Box display="flex" justifyContent="start">
              {dashboard.accessLevel === 'Edit' ? (
                <DashboardListItemMenu
                  dashboard={dashboard}
                  folders={folders}
                  handleMoveToFolder={handleMoveToFolder}
                />
              ) : (
                <Box w={8} />
              )}
            </Box>
          </SimpleGrid>
        </Flex>
      </Box>
    </ListItem>
  )
}

const MoveToFolderMenuButton = React.memo(
  React.forwardRef<
    HTMLButtonElement,
    {
      onClick: () => void
    }
  >(({ onClick }, ref) => {
    const handleOnClick = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        onClick()
        e.stopPropagation()
      },
      [onClick],
    )
    return (
      <MenuButton as={chakra.button} ref={ref} onClick={handleOnClick} w="100%">
        <MenuItem icon={<MdMoveToInbox />}>
          <Trans>Move to folder</Trans>
        </MenuItem>
      </MenuButton>
    )
  }),
)

const DashboardListItemMenu: React.FC<{
  dashboard: DashboardListItemType
  folders: DashboardFolder[] | undefined
  handleMoveToFolder: (folderUid: string | null) => void
}> = React.memo(({ dashboard, folders, handleMoveToFolder }) => {
  return (
    <Menu isLazy>
      <MenuButton
        alignItems="start"
        variant="ghost"
        as={IconButton}
        icon={<MdMoreVert />}
        onClick={(e) => e.stopPropagation()}
        mx={0}
        w={8}
      />

      <MenuList>
        <MenuItem
          as={FolderMenu}
          closeOnSelect={false}
          currentFolderUid={dashboard.folderUid}
          folders={folders}
          moveToFolder={handleMoveToFolder}
          MenuButtonComponent={MoveToFolderMenuButton}
        />
      </MenuList>
    </Menu>
  )
})
